<template>
<div class="row">
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <div class="col-12" v-if="!data.length">
        <h4>ارسال من ملف اكسل مع المتغيرات</h4>
        <br><br>
            ملف الExcel (.xls)
            <br /><br />
            <input
                id="ingredient_file"
                type="file"
            />
            <div id="progress-wrp">
                <div class="progress-bar"></div>
                <div class="status">0%</div>
            </div>
        </div>
        <div class="col-12" v-if="data.length">
            <div v-if="step == 0">
                <div class="form-group">
                  <h5 for="">اختر حقل رقم الجوال</h5>
                  <select class="form-control" v-model="inputs_values['phone']">
                    <option value="--">-- اختر --</option>
                    <option v-for="i in inputs" :key="i.index" :value="i.index">{{ i.title }}</option>
                  </select>
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-primary" @click="step = 1">التالي</button>
                </div>
            </div>
        </div>
        <div class="col-12" v-if="step == 1">
            <div class="border card card-body table-responsive col-12">
                <table class="table table-bordered table-hover">
                    <tbody>
                        <tr v-for="i in inputs" :key="i.index + '1'">
                            <td>
                                {{ i.title }}
                            </td>
                            <td>
                                <input style="border:none" onclick="this.select()" :value="`{${i.index}}`">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div
            class="col-12 alert alert-warning text-danger g"
            v-if="devices.length == 0"
          >
            ليس لديك اي اجهزة في حسابك.
          </div>
          <template v-for="device in devices">
            <div
              class="col-12 col-lg-6"
              :key="device._id"
              v-if="device.status == 1"
            >
              <div class="card card-body border">
                <b-form-checkbox
                  checked="true"
                  name="check-button"
                  inline
                  :value="device._id"
                  v-model="selected_devices"
                >
                  <h4>
                    <img
                      :src="device.info.image"
                      :style="
                        `width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-radius: 10px; ` +
                        (selected_devices.includes(device._id)
                          ? 'border: 4px solid green;'
                          : 'border: 4px solid red')
                      "
                    />
                    {{ device.title }}
                    <br />
                    <small
                      >{{ device.phone }}
                      <span class="text-muted">{{
                        device.info.bio
                      }}</span></small
                    >
                  </h4>
                </b-form-checkbox>
              </div>
            </div></template
          >
            </div>
            <div class="form-group">
              <h5 for="">الرسالة</h5>
              <textarea class="form-control" v-model="message" rows="5"></textarea>
            </div>
            <div class="col-12 g text-center">
                <button class="btn btn-primary" @click="send()">ارسال الآن</button>
            </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            data: [],
            th: [],
            selected_devices: [],
            inputs: [],
            inputs_values: {
                phone: '--'
            },
            step: 0,
            message: "",
            groups: [],
            devices: []
        }
    },
    created(){
        var g = this;
    $.post(api + "/user/contacts/groups/list", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.groups = r.response;
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
      $.post(api + "/user/devices/list", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          g.devices = r.response;
          g.loading = false;
          g.devices.forEach((element) => {
            g.selected_devices.push(element._id);
          });
        })
        .catch(function () {
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة في الاتصال",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
        setTimeout(() => {
            var g = this;
            var Upload = function (file) {
                this.file = file;
            };

            Upload.prototype.getType = function () {
                return this.file.type;
            };
            Upload.prototype.getSize = function () {
                return this.file.size;
            };
            Upload.prototype.getName = function () {
                return this.file.name;
            };
            Upload.prototype.doUpload = function () {
                var that = this;
                var formData = new FormData();

                // add assoc key values, this will be posts values
                formData.append("file", this.file, this.getName());
                formData.append("upload_file", true);

                $.ajax({
                type: "POST",
                url: "https://private-api.brmja.com/excel",
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                        "progress",
                        that.progressHandling,
                        false
                    );
                    }
                    return myXhr;
                },
                success: function (data) {
                    // your callback here
                    $("#progress-wrp").fadeOut("slow");
                    g.data = data[0]
                    var index = -1
                    g.inputs = g.data[0].map(function(a){
                        index++
                        if(a){
                          return {
                              title: a?.toString()?.trim(),
                              index: index
                          }
                        }
                    })
                    g.inputs.forEach(function(a){
                        g.inputs_values[a.title.trim()] = null
                    })
                    g.data.shift()
                },
                error: function (error) {
                    // handle error
                    alert("حدث خطأ");
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
                });
            };

            Upload.prototype.progressHandling = function (event) {
                var percent = 0;
                var position = event.loaded || event.position;
                var total = event.total;
                var progress_bar_id = "#progress-wrp";
                if (event.lengthComputable) {
                percent = Math.ceil((position / total) * 100);
                }
                // update progressbars classes so it fits your code
                $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
                $(progress_bar_id + " .status").text(percent + "%");
            };
            $("#ingredient_file").on("change", function (e) {
            $("#progress-wrp").fadeIn("slow");
            var file = $(this)[0].files[0];
            var upload = new Upload(file);

            // maby check size or type here with upload.getSize() and upload.getType()

            // execute upload
            upload.doUpload();
            });
        }, 1000);
    },
    methods: {
        send: function(){
            var g = this, arr = [];
            g.data.forEach(function(a){
                a.message = g.message
                g.inputs.forEach(function(i){
                    a.message = a.message.replace(`{${i.index}}`, a[i.index])
                })
                arr.push({
                    phone: a[g.inputs_values['phone']],
                    message: a.message
                })
            })
          $.post(api + "/user/messages/send", {
            jwt: g.user.jwt,
            devices: g.selected_devices,
            numbers: JSON.stringify(arr),
            file: "",
            custom_message: true
          })
            .then(function (r) {
              g.loading = false;
              if (r.status == 100) {
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "تم بدء عملية الإرسال بنجاح",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                g.$router.push("/archive");
              } else {
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: r.response,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              }
            })
            .catch(function () {
              g.loading = false;
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث مشكلة في الاتصال",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            });
        }
    }
}
</script>


<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>